//ADMIN ROUTES
const prefix = '/photographs'

export const PHOTOGRAPHS = `${prefix}`
export const ADMIN_PHOTOGRAPHS = `${prefix}/admin`

export const photographsFromAlbumById = (albumId: string) => {
  return `${prefix}/album/${albumId}/admin`
}

export const suspendPhotographById = (photographId: string) => {
  return `${prefix}/${photographId}/suspend`
}
export const activatePhotographById = (photographId: string) => {
  return `${prefix}/${photographId}/activate`
}

export const deletePhotographById = (photographId: string) => {
  return `${prefix}/${photographId}`
}

export const updatePhotographById = (photographId: string) => {
  return `${prefix}/${photographId}`
}

export const photographsFromDiscountById = (discountId: string) => {
  return `${prefix}/discount/${discountId}/admin`
}

export const downloadFreePhotograph = (photographId: string) => {
  return `/photographs/${photographId}/download-url`
}

export const createCoverPhotograph = (type: 'event' | 'album', id: string) => {
  return `/photographs/${type}s/${id}/cover-photograph`
}
