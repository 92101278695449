import { makeAutoObservable, runInAction } from 'mobx'
import Album from '../../models/Album'
import AlbumService from '../../services/AlbumService'
import AuthStore from '../../stores/AuthStore'
import CreateOrEditAlbumRequestInterface from 'services/Interfaces/Album/CreateOrEditAlbumRequest.interface'
import { isNil } from 'lodash'

class AlbumStore {
  public albums: Album[]
  public isLoading: boolean
  public paginator: any
  public isDeleting: boolean
  public error?: any
  private readonly albumService: AlbumService
  constructor(private readonly authStore: AuthStore) {
    this.reset()
    makeAutoObservable(this)
    this.albumService = new AlbumService()
  }

  reset() {
    this.albums = []
    this.paginator = null
    this.isLoading = false
    this.isDeleting = false
    this.error = false
  }

  async fetchAlbums(page: number, take: number, search?: string) {
    runInAction(() => {
      this.isLoading = true
    })
    try {
      const response = await this.albumService.fetchAlbums(
        this.authStore.getAdminToken(),
        page,
        take,
        search
      )
      runInAction(() => {
        this.isLoading = false
        this.albums = response.albums
        this.paginator = response.paginator
        this.error = null
      })
      return response
    } catch (e) {
      this.error = e
      this.isLoading = false
    }
  }

  async createAlbum(payload: CreateOrEditAlbumRequestInterface) {
    runInAction(() => {
      this.isLoading = true
    })
    payload.ownerId = this.authStore.getLoggedUser().id
    payload.isActive = true

    if (!isNil(payload.quantityDiscountId) && payload.quantityDiscountId === '') {
      payload.quantityDiscountId = undefined
    }

    try {
      const newAlbum = await this.albumService.createAlbum(this.authStore.getAdminToken(), payload)
      runInAction(() => {
        this.isLoading = false
      })
      return newAlbum
    } catch (e: any) {
      runInAction(() => {
        this.isLoading = false
        this.error = e
      })
    }
  }

  async editAlbum(payload: CreateOrEditAlbumRequestInterface) {
    runInAction(() => {
      this.isLoading = true
    })

    if (!isNil(payload.quantityDiscountId) && payload.quantityDiscountId === '') {
      payload.quantityDiscountId = undefined
    }

    try {
      const updatedAlbum = await this.albumService.editAlbum(
        this.authStore.getAdminToken(),
        payload
      )
      runInAction(() => {
        this.isLoading = false
      })

      return Album.init(updatedAlbum)
    } catch (e: any) {
      runInAction(() => {
        this.isLoading = false
        this.error = e
      })
    }
  }

  async fetchAdminAlbumsByLocationId(locationId: string, page: number) {
    runInAction(() => {
      this.isLoading = true
    })
    try {
      const response = await this.albumService.fetchAdminAlbumsByLocationId(
        this.authStore.getAdminToken(),
        locationId,
        page
      )
      runInAction(() => {
        this.isLoading = false
        this.albums = response.albums
        this.paginator = response.paginator
        this.error = null
      })
      return response
    } catch (e) {
      this.error = e
      this.isLoading = false
    }
  }

  async fetchAdminAlbumsByActivityId(activityId: string, page: number) {
    runInAction(() => {
      this.isLoading = true
    })
    try {
      const response = await this.albumService.fetchAdminAlbumsByActivityId(
        this.authStore.getAdminToken(),
        activityId,
        page
      )
      runInAction(() => {
        this.isLoading = false
        this.albums = response.albums
        this.paginator = response.paginator
        this.error = null
      })
      return response
    } catch (e) {
      this.error = e
      this.isLoading = false
    }
  }

  async suspendAlbum(albumId: string) {
    runInAction(() => {
      this.isLoading = true
    })
    try {
      const albumSuspended = await this.albumService.suspendAlbum(
        albumId,
        this.authStore.getToken()
      )
      runInAction(() => {
        this.isLoading = false
      })
      return albumSuspended
    } catch (e) {
      this.error = e
      this.isLoading = false
    }
  }

  async activateAlbum(albumId: string) {
    runInAction(() => {
      this.isLoading = true
    })
    try {
      const albumActivated = await this.albumService.activateAlbum(
        this.authStore.getToken(),
        albumId
      )
      runInAction(() => {
        this.isLoading = false
      })
      return albumActivated
    } catch (e) {
      this.error = e
      this.isLoading = false
    }
  }

  async deleteAlbum(albumId: string) {
    runInAction(() => {
      this.isDeleting = true
    })
    try {
      await this.albumService.deleteAlbum(albumId, this.authStore.getToken())
      runInAction(() => {
        this.isDeleting = false
      })
    } catch (e) {
      this.error = e
      this.isDeleting = false
    }
  }
}

export default AlbumStore
