import React, { useState, useContext, useEffect } from 'react'
import { observer } from 'mobx-react'
import { useQuery } from '../../hooks/useQuery'
import StoresContext from '../../providers/storesContext'
import ActivityStore from './ActivityStore'
import Spinner from '../../components/UI/Spinner/Spinner'
import ActivityList from './components/ActivityList'
import { Activity } from '../../models/Activity'
import ResultsPager from '../../components/UI/ResultsPager'
import ActivityFormModal from './components/ActivityFormModal/ActivityFormModal'
import ActivityDeleteModal from './components/ActivityDeleteModal'
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler'
import { withTranslation } from 'react-i18next'
import axios from '../../services/axios'
import ActivitySearchBox from './components/ActivitySearchBox'
import AlbumStore from 'containers/Albums/AlbumStore'
import { isNil } from 'lodash'

const Activities = () => {
  const query = useQuery()
  const { authStore } = useContext(StoresContext)!
  const [activityStore] = useState(() => new ActivityStore(authStore))
  const [albumStore] = useState(() => new AlbumStore(authStore))
  const [search, setSearch] = useState<string | undefined>()
  const [visibleAddActivityFormModal, setVisibleAddActivityFormModal] = useState(false)
  const [activityToEdit, setActivityToEdit] = useState<Activity | null>(null)
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false)
  const [activityToDelete, setActivityToDelete] = useState<Activity | null>(null)
  const [deletionAdminActivityError, setDeletionAdminActivityError] = useState<string>()

  const page = Number(query.get('page')) || 1

  const hideAddActivityModal = () => {
    setVisibleAddActivityFormModal(false)
    // avoids showing undefined name while modal is fading out
    setTimeout(() => setActivityToEdit(null), 300)
  }

  const afterSaveActivity = () => {
    activityStore.fetchAdminActivities(page)
    hideAddActivityModal()
  }

  const handleGetAlbums = (activity: Activity) => {
    albumStore.fetchAdminAlbumsByActivityId(activity.id, 1)
  }

  //Delete activity
  const handleClickDelete = (activity: Activity) => {
    albumStore.reset()
    handleGetAlbums(activity)
    showDeleteConfirm(activity)
  }

  const showDeleteConfirm = (activity) => {
    setVisibleDeleteModal(true)
    setActivityToDelete(activity)
  }

  const hideDeleteConfirm = () => {
    setVisibleDeleteModal(false)
    setDeletionAdminActivityError('')
    // avoids showing undefined name while modal is fading out
    setTimeout(() => setActivityToDelete(null), 300)
  }

  const handleDeleteAdminActivity = (activity: Activity) => {
    if (!isNil(activity)) {
      activityStore.mergeActivity(activityToDelete, activity).then((error) => {
        if (error) {
          setDeletionAdminActivityError(error)
        } else {
          hideDeleteConfirm()
          activityStore.fetchAdminActivities(page)
        }
      })
    } else {
      if (albumStore.albums?.length > 0) {
        setDeletionAdminActivityError('Please select an activity to transfer the albums')
      } else {
        activityStore.deleteActivity(activityToDelete).then((error) => {
          if (error) {
            setDeletionAdminActivityError(error)
          } else {
            hideDeleteConfirm()
            activityStore.fetchAdminActivities(page).then(() => {})
          }
        })
      }
    }
  }

  //Edit Activity
  const handleClickEdit = (activity: Activity) => {
    setActivityToEdit(activity)
    setVisibleAddActivityFormModal(true)
  }

  const handleSearch = (textToSearch: string | undefined) => {
    activityStore.fetchAdminActivities(1, textToSearch)
    setSearch(textToSearch)
  }

  //Edit Spot
  const handleUndoDelete = (activity: Activity) => {
    activity.deletedAt = null
    activityStore.updateActivity(activity).then((error) => {
      if (error) {
        setDeletionAdminActivityError(error)
      } else {
        hideDeleteConfirm()
        activityStore.fetchAllAdminActivities().then(() => {})
        activityStore.fetchAdminActivities(page)
      }
    })
  }

  useEffect(() => {
    if (search) activityStore.fetchAdminActivities(page, search).then(() => {})
    else activityStore.fetchAdminActivities(page).then(() => {})

    activityStore.fetchAllAdminActivities().then(() => {})
  }, [page])

  if (activityStore.isLoading) {
    return (
      <div className="container flex justify-center h-screen w-screen">
        <div className="mt-32 mr-32 h-16 w-16 ">
          <Spinner size={11} color="text-spotted-gold" />
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col">
      <ActivitySearchBox handleSearch={handleSearch} search={search} />
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <ActivityList
            activities={activityStore.activities}
            isLoading={activityStore.isLoading}
            handleUndoDelete={handleUndoDelete}
            handleClickEdit={handleClickEdit}
            handleClickDelete={handleClickDelete}
            handleAddActivity={() => setVisibleAddActivityFormModal(true)}
          />
          {activityStore.activities.length > 0 && !activityStore.isLoading && (
            <ResultsPager paginator={activityStore.paginator} query={query} />
          )}
        </div>
      </div>
      <ActivityFormModal
        activityToEdit={activityToEdit}
        afterSaveActivity={afterSaveActivity}
        handleClose={hideAddActivityModal}
        open={visibleAddActivityFormModal}
      />

      <ActivityDeleteModal
        activityToDelete={activityToDelete!}
        activities={activityStore.allActivities}
        albums={albumStore.albums}
        handleDelete={handleDeleteAdminActivity}
        handleClose={hideDeleteConfirm}
        opened={visibleDeleteModal}
        isDeleting={activityStore.isDeleting}
        error={deletionAdminActivityError}
      />
    </div>
  )
}

export default withErrorHandler(withTranslation('common')(observer(Activities)), axios)
