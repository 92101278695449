import { makeAutoObservable, runInAction } from 'mobx'
import AuthStore from 'stores/AuthStore'
import { isNil } from 'lodash'
import i18n from 'i18next'
import FileService from 'services/FileService'
import PhotographService from 'services/PhotographService'

export type ProgressInfo = {
  fileName: string
  percentage: number
  requestSent: boolean
  error: boolean
  size: number
  type: string
}

class UploadCoverPhotographStore {
  public coverPhotographUrl: string | null
  public progressInfo: ProgressInfo | undefined
  public imageSubmitted: boolean
  public uploadingImage: boolean
  public image: File | undefined
  public isLoading: boolean
  public error: any
  public serverError: string
  private photographService: PhotographService
  private fileService: FileService
  constructor(private readonly authStore: AuthStore) {
    this.reset()
    makeAutoObservable(this)
    this.photographService = new PhotographService()
    this.fileService = new FileService()
  }

  reset() {
    this.coverPhotographUrl = null
    this.image = undefined
    this.imageSubmitted = false
    this.progressInfo = undefined
    this.uploadingImage = false
    this.isLoading = false
    this.error = false
    this.serverError = ''
  }

  imageUploaded() {
    return this.imageSubmitted && this.progressInfo?.percentage === 100
  }

  uploadImageFail() {
    const _progressInfo = this.progressInfo
    _progressInfo!.error = true
    _progressInfo!.percentage = 100
    this.changeProgressInfo(_progressInfo!)
    this.error = null
    this.uploadingImage = false
  }

  async uploadCoverPhotograph(id: string, type: 'event' | 'album'): Promise<void> {
    runInAction(() => {
      this.isLoading = true
    })
    this.uploadCoverPhotographStart()
    const promises: Promise<string>[] = []
    try {
      const response = await this.photographService.createCoverPhotograph(
        this.image!.type,
        id,
        type,
        this.authStore.getAdminToken()
      )

      if (!isNil(response)) {
        runInAction(() => {
          this.coverPhotographUrl = response.photographUrl
        })
        try {
          promises.push(
            this.fileService.uploadFileToUrl(
              this.image!,
              response.uploadUrl,
              (event: any) => {
                const _progressInfo = this.progressInfo
                _progressInfo!.percentage = Math.round((100 * event.loaded) / event.total)
                _progressInfo!.requestSent = true
                this.changeProgressInfo(_progressInfo!)
              },
              () => {
                this.uploadImageFail()
              }
            )
          )
        } catch (e: any) {
          runInAction(() => {
            this.uploadImageFail()
            this.serverError = i18n.t(
              'Something went wrong, please check the provided data and try again'
            )
          })
        }
      }
      await Promise.all(promises)
      this.isLoading = false
    } catch (e: any) {
      runInAction(() => {
        this.serverError = i18n.t(
          'Something went wrong, please check the provided data and try again'
        )
      })
    }
  }

  changeProgressInfo(val: ProgressInfo) {
    this.progressInfo = val
  }
  uploadCoverPhotographStart() {
    this.error = null
    this.uploadingImage = true
  }
  changeImageSubmitted(val: boolean) {
    this.imageSubmitted = val
  }
  changeImage(val: File) {
    this.image = val
  }
  uploadImageToAlbumStart() {
    this.error = null
    this.uploadingImage = true
  }

  changeCoverPhotographUrl(url: string) {
    this.coverPhotographUrl = url
  }
}

export default UploadCoverPhotographStore
