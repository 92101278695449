import axios, { adminApiInstance } from './axios'
import * as paths from '../services/Paths'
import { AxiosResponse } from 'axios'
import { parseAxiosErrorResponse } from '../utility'
import Album from '../models/Album'
import CreateOrEditAlbumRequestInterface from './Interfaces/Album/CreateOrEditAlbumRequest.interface'
import AlbumInterface from './Interfaces/Album/Album.interface'

class AlbumService {
  deleteAlbum(albumId: string, token: string) {
    return axios
      .delete(paths.deleteAlbum(albumId), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => {
        return response.data
      })
      .catch(parseAxiosErrorResponse)
  }

  fetchAlbum(albumId: string, token: string) {
    return axios
      .get(paths.albumDetail(albumId), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  suspendAlbum(albumId: string, token: string) {
    return axios
      .post(paths.suspendAlbumById(albumId), albumId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data)
  }

  activateAlbum(token: string, albumId: string) {
    return axios
      .post(paths.activateAlbumById(albumId), albumId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  //ADMIN REQUESTS

  createAlbum(token: string, albumData: CreateOrEditAlbumRequestInterface): Promise<Album> {
    return adminApiInstance
      .post(paths.ADMIN_ALBUMS, albumData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => response.data)
      .catch(parseAxiosErrorResponse)
  }

  fetchAlbums(token: string, page: number, take: number, description?: string) {
    const path = description
      ? `${paths.ADMIN_ALBUMS}?page=${page}&take=${take}&description=${description}`
      : `${paths.ADMIN_ALBUMS}?page=${page}&take=${take}`
    return adminApiInstance
      .get(path, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }: AxiosResponse) => {
        const albumModels = data.data.map((e) => Album.init(e))

        return {
          albums: albumModels,
          paginator: data.meta,
        }
      })
      .catch(parseAxiosErrorResponse)
  }

  fetchAdminAlbums(token: string, page: number) {
    return adminApiInstance
      .get(`${paths.ADMIN_ALBUMS}?page=${page}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }: AxiosResponse) => {
        return {
          albums: data.data,
          paginator: data.meta,
        }
      })
      .catch(parseAxiosErrorResponse)
  }

  fetchAdminAlbumsByLocationId(token: string, locationId: string, page: number) {
    return adminApiInstance
      .get(`${paths.ADMIN_ALBUMS_BY_LOCATION_ID}?page=${page}&locationId=${locationId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }: AxiosResponse) => {
        return {
          albums: data.data,
          paginator: data.meta,
        }
      })
      .catch(parseAxiosErrorResponse)
  }

  fetchAdminAlbumsByActivityId(token: string, activityId: string, page: number) {
    return adminApiInstance
      .get(`${paths.ADMIN_ALBUMS_BY_ACTIVITY_ID}?page=${page}&activityId=${activityId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }: AxiosResponse) => {
        return {
          albums: data.data,
          paginator: data.meta,
        }
      })
      .catch(parseAxiosErrorResponse)
  }

  editAlbum(token: string, albumData: CreateOrEditAlbumRequestInterface): Promise<AlbumInterface> {
    return adminApiInstance
      .put(paths.updateAlbumById(albumData.id!), albumData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response: AxiosResponse) => response.data)
      .catch(parseAxiosErrorResponse)
  }
}

export default AlbumService
