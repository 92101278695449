import React, { useContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation, withTranslation } from 'react-i18next'
import StoresContext from '../../../../providers/storesContext'
import Modal, { ModalButtonsDisplay, ModalSize } from '../../../../components/UI/Modal/Modal'
import ServerError from '../../../../components/UI/ServerError'
import Spinner from '../../../../components/UI/Spinner/Spinner'
import { isNil } from 'lodash'
import { Activity } from 'models/Activity'
import { Location } from 'models/Location'
import { CurrencyEnum } from 'models/CurrencyEnum'
import { Autocomplete, Checkbox, TextField } from '@mui/material'
import UploadCoverPhotograph from 'components/UI/ImageUploader/UploadCoverPhotograph'
import { useFormik } from 'formik'
import moment from 'moment'
import Album from 'models/Album'
import CreateOrEditAlbumRequestInterface from 'services/Interfaces/Album/CreateOrEditAlbumRequest.interface'
import AlbumStore from 'containers/Albums/AlbumStore'
import getAlbumValidationSchema from './AlbumValidationSchema'
import { Event } from 'models/Event'
import ActivityStore from 'containers/Activities/ActivityStore'
import LocationStore from 'containers/Locations/LocationStore'
import EventStore from 'containers/Events/EventsStore'

type AlbumFormModalType = {
  open: boolean
  handleClose: (album?: Album) => void
  albumToEdit?: Album | null
  albumStore: AlbumStore
}

const AlbumFormModal = ({
  open,
  handleClose,
  albumToEdit = null,
  albumStore,
}: AlbumFormModalType) => {
  const { t } = useTranslation()
  const { authStore } = useContext(StoresContext)!
  const [activityStore] = useState(() => new ActivityStore(authStore))
  const [locationStore] = useState(() => new LocationStore(authStore))
  const [eventStore] = useState(() => new EventStore(authStore))
  const [currencies, setCurrencies] = useState<CurrencyEnum[]>([])
  const [activities, setActivities] = useState<Activity[]>([])
  const [locations, setLocations] = useState<Location[]>([])
  const [events, setEvents] = useState<Event[]>([])

  useEffect(() => {
    const fetchData = async () => {
      const [activitiesResult, locationsResult, eventsResult] = await Promise.all([
        activityStore.fetchAllAdminActivities(),
        locationStore.fetchAllAdminLocations(),
        eventStore.searchAdminEvents(1, 25),
      ])

      if (activitiesResult) setActivities(activitiesResult)
      if (locationsResult) setLocations(locationsResult)
      if (eventsResult) setEvents(eventsResult.events)

      loadCurrencies()
    }

    fetchData()
  }, [])

  const loadCurrencies = async () => {
    const currenciesList = Object.values(CurrencyEnum)
    setCurrencies(currenciesList)
  }

  const initialValues: CreateOrEditAlbumRequestInterface = !isNil(albumToEdit)
    ? {
        id: albumToEdit.id || '',
        description: albumToEdit?.description || '',
        activityId: albumToEdit?.activity?.id || '',
        locationId: albumToEdit?.location?.id || '',
        takenDate: albumToEdit?.takenDate || new Date(new Date().setHours(0, 0, 0, 0)),
        currency: albumToEdit?.currency || '',
        defaultImagePrice: albumToEdit?.defaultImagePrice || 0,
        defaultPackagePrice: albumToEdit?.defaultPackagePrice || undefined,
        quantityDiscountId: albumToEdit?.quantityDiscountId || undefined,
        isFree: albumToEdit?.isFree || false,
        eventId: albumToEdit?.eventId || undefined,
      }
    : {
        description: '',
        activityId: '',
        locationId: '',
        takenDate: new Date(new Date().setHours(0, 0, 0, 0)),
        currency: undefined,
        defaultImagePrice: 0,
        defaultPackagePrice: undefined,
        quantityDiscountId: undefined,
        isFree: false,
        eventId: undefined,
      }

  const formik = useFormik({
    initialValues,
    validationSchema: getAlbumValidationSchema(),
    onSubmit: (values) => {
      if (isNil(albumToEdit)) {
        if (typeof values.defaultPackagePrice === 'string') {
          values.defaultPackagePrice = undefined
        }
        albumStore.createAlbum(values).then((newAlbum) => {
          if (newAlbum) {
            handleClose(newAlbum)
            albumStore.fetchAlbums(1, 25)
          }
        })
      } else {
        albumStore.editAlbum(values).then(() => {
          handleClose(albumToEdit)
          albumStore.fetchAlbums(1, 25)
        })
      }
    },
  })

  const activityOptions = activities
    .map((activity) => {
      return { value: activity.id, label: t(activity.name).toUpperCase() }
    })
    .sort((a, b) => -b.label[0].localeCompare(a.label[0]))

  const locationOptions = locations
    .map((location) => {
      return { value: location.id, label: location.spotName.toUpperCase() }
    })
    .sort((a, b) => -b.label[0].localeCompare(a.label[0]))

  const eventOptions = events
    .map((event) => {
      return { value: event.id, label: event.name.toLocaleUpperCase() }
    })
    .sort((a, b) => -b.label[0].localeCompare(a.label[0]))

  useEffect(() => {
    if (!isNil(formik.values.eventId)) {
      const event = events.find((event) => event.id === formik.values.eventId)
      if (!isNil(event)) {
        formik.setValues({
          ...formik.values,
          activityId: event?.activityId ?? formik.values.activityId,
          locationId: event?.locationId ?? formik.values.locationId,
          defaultImagePrice: event?.defaultImagePrice ?? formik.values.defaultImagePrice,
          defaultPackagePrice: event.defaultPackagePrice ?? formik.values.defaultPackagePrice,
          currency: event?.currency ?? formik.values.currency,
          quantityDiscountId: event?.quantityDiscountId ?? formik.values.quantityDiscountId,
          isFree: event.isFree ?? formik.values.isFree,
        })
      }
    }
  }, [formik.values.eventId])

  if (albumStore.isLoading) {
    return (
      <div className="container flex justify-center">
        <div className="mt-14">
          <Spinner size={11} color="text-spotted-gold" />
        </div>
      </div>
    )
  }

  return (
    <Modal
      okMessage={isNil(albumToEdit) ? t('Add') : t('Save')}
      loadingOk={albumStore.isLoading}
      onCancel={handleClose}
      onSuccess={formik.handleSubmit}
      opened={open}
      buttonsDisplay={ModalButtonsDisplay.ONLY_OK_BUTTON}
      modalSize={ModalSize.MEDIUM}
    >
      <div className="w-full flex flex-col gap-3">
        <div className="text-xl text-gray-800 font-bold text-start capitalize">
          {isNil(albumToEdit) ? t('New Album') : t('Edit Album')}
        </div>
        {albumStore.error && <ServerError message={t(albumStore.error?.message)} />}
        <div className="w-full flex flex-col md:flex-row items-center gap-2">
          <div className={`w-full ${!isNil(albumToEdit) ? 'md:w-2/3' : 'md:w-1/2'}`}>
            <TextField
              fullWidth
              variant="outlined"
              id="description"
              name="description"
              label={t('Description')}
              required
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.description && Boolean(formik.errors.description)}
              helperText={formik.touched.description && formik.errors.description}
            />
          </div>
        </div>
        <div className="w-full flex flex-col md:flex-row md:mb-4 gap-3 items-top">
          {!isNil(albumToEdit) && (
            <div className="w-1/2">
              <p className="text-gray-400 font-bold text-sm mb-1">{t('Cover photograph')}</p>
              <UploadCoverPhotograph album={albumToEdit} />
            </div>
          )}
          <div
            className={`w-full flex flex-col justify-between gap-3 ${
              !isNil(albumToEdit) && 'md:w-2/3'
            }`}
          >
            <div className={`w-full md:flex gap-3 ${!isNil(albumToEdit) && 'md:flex-col'}`}>
              <div className={`w-full mb-3 ${isNil(albumToEdit) ? 'md:w-1/2' : 'md:mt-6'}`}>
                <TextField
                  fullWidth
                  id="takenDate"
                  name="takenDate"
                  label={t('Taken Date')}
                  required
                  InputLabelProps={{ shrink: true }}
                  type="TakenDate"
                  value={moment.utc(formik.values.takenDate).format('YYYY-MM-DD')}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.takenDate && Boolean(formik.errors.takenDate)}
                  helperText={
                    formik.touched.takenDate
                      ? typeof formik.errors.takenDate === 'string'
                        ? formik.errors.takenDate
                        : ''
                      : ''
                  }
                />
              </div>
            </div>
            <div className={`w-full md:flex gap-3 ${!isNil(albumToEdit) && 'md:flex-col'}`}>
              <div className={`w-full mb-3 ${isNil(albumToEdit) ? 'md:w-1/2' : 'md:mb-3'}`}>
                <Autocomplete
                  options={eventOptions}
                  value={
                    eventOptions.find((option) => option.value === formik.values.eventId) || null
                  }
                  getOptionLabel={(option) => option.label}
                  onChange={(event, newValue) => {
                    formik.setFieldValue('eventId', newValue ? newValue.value : null)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      variant="outlined"
                      name="eventId"
                      id="eventId"
                      label={t('Event')}
                      error={formik.touched.eventId && Boolean(formik.errors.eventId)}
                      helperText={formik.touched.eventId && formik.errors.eventId}
                    />
                  )}
                />
              </div>
              <div className={`w-full mb-3 ${isNil(albumToEdit) ? 'md:w-1/2' : 'md:mb-3'}`}>
                <Autocomplete
                  options={activityOptions}
                  value={
                    activityOptions.find(
                      (activity) => activity.value === formik.values.activityId
                    ) || null
                  }
                  getOptionLabel={(option) => option.label}
                  onChange={(activity, newValue) => {
                    formik.setFieldValue('activityId', newValue ? newValue.value : '')
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      variant="outlined"
                      name="activityId"
                      id="activityId"
                      label={t('Activity')}
                      required
                      error={formik.touched.activityId && Boolean(formik.errors.activityId)}
                      helperText={formik.touched.activityId && formik.errors.activityId}
                    />
                  )}
                />
              </div>
              <div className={`w-full ${isNil(albumToEdit) && 'md:w-1/2'}`}>
                <Autocomplete
                  options={locationOptions}
                  value={
                    locationOptions.find(
                      (location) => location.value === formik.values.locationId
                    ) || null
                  }
                  getOptionLabel={(option) => option.label}
                  onChange={(location, newValue) => {
                    formik.setFieldValue('locationId', newValue ? newValue.value : '')
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      variant="outlined"
                      name="locationId"
                      id="locationId"
                      label={t('Location')}
                      required
                      error={formik.touched.locationId && Boolean(formik.errors.locationId)}
                      helperText={formik.touched.locationId && formik.errors.locationId}
                    />
                  )}
                />
              </div>
              <div className="flex items-center gap-1 mt-1">
                <p className="text-sm font-bold text-gray-400">{t('Free Album')}</p>
                <Checkbox
                  onChange={() => formik.setFieldValue('isFree', !formik.values.isFree)}
                  checked={formik.values.isFree}
                />
              </div>
            </div>
          </div>
        </div>
        {!formik.values.isFree && (
          <>
            <div className="w-full flex gap-3 flex-col md:flex-row">
              <div className="w-full md:w-1/4">
                <Autocomplete
                  options={currencies}
                  value={currencies.find((currency) => currency === formik.values.currency) || null}
                  onChange={(event, newValue) => {
                    formik.setFieldValue('currency', newValue ? newValue : undefined)
                  }}
                  getOptionLabel={(option) => (option ? option.toUpperCase() : '')}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      name="currency"
                      id="currency"
                      label={t('Currency')}
                      required
                      error={formik.touched.currency && Boolean(formik.errors.currency)}
                      helperText={formik.touched.currency && formik.errors.currency}
                    />
                  )}
                />
              </div>
              <div className="w-full md:w-1/4">
                <TextField
                  id="defaultImagePrice"
                  name="defaultImagePrice"
                  label={t('Default Image Price')}
                  fullWidth
                  required
                  type="number"
                  value={formik.values.defaultImagePrice}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.defaultImagePrice && Boolean(formik.errors.defaultImagePrice)
                  }
                  helperText={formik.touched.defaultImagePrice && formik.errors.defaultImagePrice}
                />
              </div>
              <div className="w-full md:w-1/4">
                <TextField
                  id="defaultPackagePrice"
                  name="defaultPackagePrice"
                  label={t('Default Package Price')}
                  type="number"
                  fullWidth
                  value={formik.values.defaultPackagePrice}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.defaultPackagePrice && Boolean(formik.errors.defaultPackagePrice)
                  }
                  helperText={
                    formik.touched.defaultPackagePrice && formik.errors.defaultPackagePrice
                  }
                />
              </div>
            </div>
            <TextField
              fullWidth
              variant="outlined"
              id="quantityDiscountId"
              name="quantityDiscountId"
              label={t('QuantityDiscountId')}
              value={formik.values.quantityDiscountId}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.quantityDiscountId && Boolean(formik.errors.quantityDiscountId)}
              helperText={formik.touched.quantityDiscountId && formik.errors.quantityDiscountId}
            />
          </>
        )}
      </div>
    </Modal>
  )
}

export default withTranslation('common')(observer(AlbumFormModal))
